import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { ProfileComponent } from "./profile/profile.component";
import { SignupComponent } from "./signup/signup.component";
import { LandingComponent } from "./landing/landing.component";
import { SubscribeComponent } from "./subscribe/subscribe.component";
import { LoginComponent } from "./login/login.component";
import { TermComponent } from "./term/term.component";
import { FaqComponent } from "./faq/faq.component";
import { PaymentComponent } from './payment/payment.component';
import { ResultComponent } from './result/result.component';


const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "user-profile", component: ProfileComponent },
  { path: "register", component: SignupComponent },
  { path: "landing", component: LandingComponent },
  { path: "term", component: TermComponent},
  { path: "faq", component: FaqComponent},
  {
    path: "subscribe/:uid",
    component: SubscribeComponent
  },
  {
    path: 'payment/:subscribe',
    component: PaymentComponent
},
{
    path: 'result/:subscribe',
    component: ResultComponent
},
  { path: "", redirectTo: "home", pathMatch: "full" }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule {}
