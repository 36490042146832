import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit{

  paymentResponse: any;
  sub: any;
  subscribe: any;
  uid:any;

  error = false;
  errortext ;

  PurchaseURL:string =  `https://us-central1-gh-hendi.cloudfunctions.net/api/payment/checkout`;
  // PurchaseURL:string = this.csvc.getDomain() + "/api/payment/checkout";


  constructor(private http: HttpClient,
              protected route: ActivatedRoute,
              protected router: Router) {  

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
     this.subscribe = params['subscribe'];
     });
     this.uid = sessionStorage.getItem('uid');
   }
  
  onDropinLoaded(event) {
    console.log("dropin loaded...");
  }

  onPaymentStatus(response){
    

    let respond = response;
    console.log(respond);
    let respondcode = respond.result.transaction.processorResponseCode;
    console.log(respondcode);

    if(respond.result.success == false || respond == undefined){
      // console.log(response.transaction);     
        this.error = true;
        this.errortext = respond.result.transaction.processorResponseText
        // this.buy = parseInt(this.route.snapshot.params['total']);
        // this.router.navigate(['/payment', this.buy]);
        if(respondcode == 2000){
          this.error = true;
          this.errortext = 'Your card has been declined. Please use another card to proceed with payment';
          console.log(this.errortext);
        }
       
        if(respondcode == 2001){
          this.error = true;
          this.errortext = 'Your card has insufficient fund. Please use another card or another payment method.';
        }

        if(respondcode == 2002){
          this.error = true;
          this.errortext = 'Your card has reached withdrawal limit. Please contact your bank to change your account limit or use a different payment method.';
        }

        if(respondcode == 2004){
          this.error = true;
          this.errortext = 'Your card has expired. Please use another card or payment method';
        }

        if(respondcode == 2005){
          this.error = true;
          this.errortext = 'Invalid payment information. Please retry with correct information';
        }

        
        //dangerModal.show();
        //this.router.navigate(['/buysuccess', newkey]);
    
    }

    console.log("Message : " + response.message); //FAIL or SUCCESS

    if(response.result.success == true){
      this.router.navigate(['/result',this.subscribe]);
    }
   
  }

  navigateToPreviousPage(){
    this.router.navigate(['/subscribe',this.uid]);
  }

  
  

}
