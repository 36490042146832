import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import {AngularFireDatabase, AngularFireList, AngularFireObject} from "angularfire2/database";
import {Observable} from "rxjs/Observable";
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  subscribe: any;
  disabledSubmitButton: boolean = true;
  user:any;
  uid:any;
  subscribeData: Observable<any>;
  userRef: AngularFireObject<any>;
  name:any;

  @HostListener('input') oninput() {

    if (this.subscribe !== null) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    private database: AngularFireDatabase) {    
}

ngOnInit() {
  this.user = this.route.params.subscribe(params => {
    this.uid = params['uid'];
  });

  sessionStorage.setItem('uid', this.uid);
  this.userRef = this.database.object(`user_profile/details/${this.uid}`);
  this.subscribeData = this.userRef.valueChanges();
  this.subscribeData.subscribe(response => {
  this.name = response.first_name;
  this.subscribe = response.subscription;
})
}

}
