import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as firebase from 'firebase/app';
import {AngularFireDatabase, AngularFireObject} from "angularfire2/database";
import {Observable} from "rxjs/Observable";
import { browser } from 'protractor';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  sub: any;
  subscribe: any;
  space:any;
  subscribeData: Observable<any>;
  subscribeRef: AngularFireObject<any>;
  uid:any;

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              private database: AngularFireDatabase) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.subscribe = params['subscribe'];
      });
      this.subscribeRef = this.database.object(`subscription/${this.subscribe}`);
      this.subscribeData = this.subscribeRef.valueChanges();
      this.subscribeData.subscribe(response => {
      this.space = response.subs_storage;
      this.uid = sessionStorage.getItem('uid');
      this.database.object(`user_profile/details/${this.uid}`).update({subscription:this.subscribe});
    })

  }

  navigateToMainPage(){
    window.sessionStorage.clear();
    window.close();
  }

}
